import React, { FC, useMemo, useState } from 'react';
import { BackButton } from 'src/components/BackButton';
import { Button } from 'src/components/Button';
import { SmallProductCard } from 'src/components/Cards/SmallProductCard';
import { TotalsList } from 'src/components/TotalsList';
import { debounce, sendBasket } from 'src/utils';
import { useTotals } from 'src/hooks/useTotals';
import { useTelegram } from 'src/hooks/useTelegram';
import { TextArea } from 'src/components/TeaxtArea';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';

import cn from 'classnames';

export const BasketPage: FC = () => {
  const {
    basket: { basketProducts, isLoadingSendBasket },
    shops: { shop },
  } = useAppSelector(state => state);
  const { totals } = useTotals();
  const { onClose } = useTelegram();

  const [commentary, setCommentary] = useState<string>('');

  const dispatch = useAppDispatch();

  const products = useMemo(() => basketProducts[shop.typeId], [shop, basketProducts]);

  const handleComment = debounce(async (str: string) => {
    setCommentary(str);
  }, 200);

  const handleSend = async () => {
    if (!shop || !products?.length || isLoadingSendBasket) {
      return;
    }

    await sendBasket(shop, basketProducts, totals, products, commentary, dispatch).then(() =>
      onClose(),
    );
  };

  return (
    <div className="py-4 flex min-h-full flex-col gap-4">
      <div className="px-4 w-full flex items-center justify-between">
        <BackButton text="Корзина" />
      </div>

      <div className="rounded-xl mx-4 overflow-hidden">
        {Object.keys(basketProducts).map(key =>
          basketProducts[key].map((product, i) => (
            <SmallProductCard
              key={`basket-list-${product.id}`}
              product={product}
              classNames={cn({ 'border-b border-grey-200': i !== basketProducts[key].length - 1 })}
            />
          )),
        )}
      </div>

      <div className="w-full px-4">
        <TextArea onChange={handleComment} classNames="h-16" placeholder="Ваш комментарий" />
      </div>

      <div className="w-full px-4 pb-20">
        <TotalsList />
      </div>

      <div className="w-full px-4 fixed bottom-4 left-0 z-10">
        <Button
          text="Оформить заказ"
          variant="fill"
          classNames="w-full h-12"
          onClick={handleSend}
        />
      </div>
    </div>
  );
};
