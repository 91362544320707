import { FC, useEffect, useState } from 'react';
import { Input } from 'src/components/Input';
import { GroupCard } from 'src/components/Cards/GroupCard';
import { Loader } from 'src/components/Loader';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { debounce } from 'src/utils';
import { fetchCategories } from 'src/store/categories/actions';

export const CategoriesPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    shops: { shop },
    categories: { categories, isLoadingCategories },
  } = useAppSelector(state => state);

  const [search, setSearch] = useState('');

  const handleSearch = debounce(async (str: string) => {
    setSearch(str);
    await dispatch(fetchCategories({ shopId: shop.apiId, filter: str }));
  }, 400);

  useEffect(() => {
    (async () => {
      if (shop?.apiId) {
        await dispatch(fetchCategories({ shopId: shop.apiId, filter: '' }));
      }
    })();
  }, []);

  return (
    <div className="py-4 flex flex-col h-full">
      <Input
        defaultValue={search}
        onChange={handleSearch}
        classNames="mx-4 mb-4 h-12"
        placeholder="Поиск"
      />

      {isLoadingCategories ? <Loader classNames="w-1/3 mx-auto m-auto" /> : null}

      {!isLoadingCategories && !categories.length && !search ? (
        <div className="text-center font-gilroy-500 m-auto">В этом магазине еще нет групп</div>
      ) : null}

      {!isLoadingCategories && !categories.length && search ? (
        <div className="text-center font-gilroy-500 m-auto">
          По вашему запросу группы не найдены
        </div>
      ) : null}

      {!isLoadingCategories && categories.length ? (
        <div className="grid grid-cols-2 gap-3 mx-4">
          {categories.map(category => (
            <GroupCard group={category} key={`group-list-${category.id}`} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
