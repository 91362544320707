import React, { FC, useRef } from 'react';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

import cn from 'classnames';

interface InputProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  classNames?: string;
}

export const Input: FC<InputProps> = ({
  value,
  defaultValue,
  onChange,
  placeholder,
  classNames,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleChange = e => {
    onChange(e.target.value);
  };

  const resetInput = () => {
    onChange('');
    if (ref?.current) {
      ref.current.value = '';
    }
  };

  return (
    <div
      className={cn(
        'px-5 bg-white border border-grey-100 justify-between items-center',
        'rounded-full flex h-12',
        classNames,
      )}
    >
      <input
        ref={ref}
        className="w-full placeholder:text-grey-100 text-sm font-gilroy-400"
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        placeholder={placeholder || ''}
      />
      {value || ref?.current?.value ? (
        <CloseIcon className="w-4 h-4 cursor-pointer" onClick={resetInput} />
      ) : null}
    </div>
  );
};
