import { useTelegram } from 'src/hooks/useTelegram';
import { API } from 'src/services/API';

const getCategoriesById = async (shopId: string, filter: string) => {
  const { chatId } = useTelegram();

  return await API.get(`/Catalog/Groups/?shopId=${shopId}&chatId=${chatId}&filter=${filter || ''}`);
};

const getSubGroupsById = async (data: { shopId: string; groupId: string }) => {
  const { chatId } = useTelegram();

  return await API.get(
    `/Catalog/Subgroups/?shopId=${data.shopId}&chatId=${chatId}&groupId=${data.groupId}`,
  );
};

const CategoriesService = { getCategoriesById, getSubGroupsById };

export default CategoriesService;
