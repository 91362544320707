import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOrders } from './actions';
import { OrdersState } from './types';
import { IOrder } from 'src/models/IOrder';

const initialState: OrdersState = {
  orders: { current: [], archive: [], canceled: [] },
  isLoadingOrders: true,
  error: '',
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrders.pending.type]: state => {
      state.isLoadingOrders = true;
      state.orders = { current: [], archive: [], canceled: [] };
    },
    [fetchOrders.fulfilled.type]: (
      state,
      action: PayloadAction<{ current: IOrder[]; archive: IOrder[]; canceled: IOrder[] }>,
    ) => {
      state.isLoadingOrders = false;
      state.error = '';
      state.orders = action.payload;
    },
    [fetchOrders.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingOrders = false;
      state.error = action.payload;
    },
  },
});

export const {} = ordersSlice.actions;

const reducer = ordersSlice.reducer;
export default reducer;
