import { SecondAPI } from 'src/services/SecondAPI';
import { useTelegram } from 'src/hooks/useTelegram';

const getOrders = async (shopId: string, attribute?: 'current' | 'archive' | 'canceled') => {
  const { chatId } = useTelegram();

  return await SecondAPI.get(
    `/api/Catalog/GetUsersOrders?shopId=${shopId}&chatId=${chatId}&attribute=${attribute || ''}`,
  );
};

const OrdersService = { getOrders };

export default OrdersService;
