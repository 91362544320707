import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';

interface BackButtonProps {
  href?: string;
  onClick?: () => void;
  text?: string;
}

export const BackButton: FC<BackButtonProps> = ({ href, onClick, text }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    onClick && onClick();
    navigate(-1);
  };

  return href ? (
    <Link
      to={`${href}${window.location.search}`}
      onClick={onClick}
      className="flex gap-4 items-center w-full cursor-pointer"
    >
      <ArrowIcon className="w-4 h-4 min-w-[1rem]" />
      <div className="w-[70%] truncate text-black-100 text-lg font-gilroy-600">{text || ''}</div>
    </Link>
  ) : (
    <div onClick={handleBack} className="flex gap-4 items-center w-full cursor-pointer">
      <ArrowIcon className="w-4 h-4 min-w-[1rem]" />
      <div className="w-[70%] truncate text-black-100 text-lg font-gilroy-600">{text || ''}</div>
    </div>
  );
};
