import { ShopsState } from './types';
import { IShop } from 'src/models/IShop';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ShopsState = {
  shop: {} as IShop,
  animationOff: false,
};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    setShop: (state, action: PayloadAction<IShop>) => {
      state.shop = action.payload;
    },
    setAnimation: (state, action: PayloadAction<boolean>) => {
      state.animationOff = action.payload;
    },
  },
});

export const { setShop, setAnimation } = shopsSlice.actions;

const reducer = shopsSlice.reducer;
export default reducer;
