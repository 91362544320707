import { createAsyncThunk } from '@reduxjs/toolkit';
import CategoriesService from 'src/services/CategoriesService';

export const fetchCategories = createAsyncThunk(
  'categories/fetchAll',
  async (data: { shopId: string; filter: string }, thunkAPI) => {
    try {
      const response = await CategoriesService.getCategoriesById(data.shopId, data.filter);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить категории');
    }
  },
);

export const fetchSubGroups = createAsyncThunk(
  'categories/fetchSubGroups',
  async (data: { shopId: string; groupId: string }, thunkAPI) => {
    try {
      const response = await CategoriesService.getSubGroupsById(data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить подгруппы');
    }
  },
);
