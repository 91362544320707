import { FC } from 'react';
import { useTotals } from 'src/hooks/useTotals';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/redux';
import { defaultButtonsColor } from 'src/config/data';

import { ReactComponent as ShoppingCartIcon } from 'src/assets/icons/kit/shopping-cart.svg';
import cn from 'classnames';

export const Head: FC = () => {
  const location = useLocation();
  const {
    settings: { image, buttonsColor },
  } = useAppSelector(state => state.common);
  const { totalProductsCount } = useTotals();

  return (
    <div
      className="fixed z-100 top-0 left-0 w-full h-16 flex items-center px-4"
      style={{ backgroundColor: buttonsColor || defaultButtonsColor }}
    >
      <Link to={`/${window.location.search}`} className="h-10 w-28">
        <img className="w-full h-full object-contain object-left" alt="" src={image} />
      </Link>
      <Link
        to={`/orders${window.location.search}`}
        className={cn(
          {
            'bg-black text-white': location.pathname === '/orders',
            'bg-white text-black': location.pathname !== '/orders',
          },
          'ml-auto font-gilroy-500 text-extra-xs px-3 py-1.5 rounded-full mr-4 transition-full',
        )}
      >
        История заказов
      </Link>
      <Link to={`/basket${window.location.search}`} className="w-9 h-9 relative pt-2">
        <ShoppingCartIcon className="w-full h-full fill-white" />
        <div
          className={
            'rounded-full bg-black-300 p-0.5 font-gilroy-700 text-white text-xs w-fit ' +
            'min-w-[1.25rem] text-center absolute -top-0.5 -right-2'
          }
        >
          {totalProductsCount}
        </div>
      </Link>
    </div>
  );
};
