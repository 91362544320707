import { useAppSelector } from './redux';
import { ShopTypes } from '../models/IShop';
import { stringToNumber } from 'src/utils';

export type Totals = {
  [key in ShopTypes]: {
    totalShopPrice: string;
    totalShopBenefit: string;
  };
};

export const useTotals = () => {
  const {
    basket: { basketProducts },
    shops: { shop },
  } = useAppSelector(state => state);
  let totalPrice = 0;
  let totalBenefit = 0;

  const totals = {} as Totals;

  let totalProductsCount = 0;

  basketProducts?.[shop.typeId]?.forEach(product => (totalProductsCount += product.amount));

  for (const key in basketProducts) {
    const curProducts = basketProducts[Number(key) as ShopTypes];

    const price = {
      totalNewPrice: 0,
      totalOldPrice: 0,
    };

    curProducts.forEach(product => {
      price.totalNewPrice += +stringToNumber(product.newPrice)
        ? +product.amount * stringToNumber(product.newPrice)
        : 0;
      price.totalOldPrice += +stringToNumber(product.oldPrice)
        ? +product.amount * stringToNumber(product.oldPrice)
        : 0;
    });

    totalPrice += price.totalNewPrice;
    totalBenefit += price.totalOldPrice - price.totalNewPrice;

    totals[Number(key) as ShopTypes] = {
      totalShopPrice: price.totalNewPrice.toFixed(2),
      totalShopBenefit: (price.totalOldPrice - price.totalNewPrice).toString(),
    };
  }

  return {
    totalProductsCount,
    totalPrice: totalPrice.toFixed(0),
    totalBenefit: totalBenefit.toFixed(0),
    totals,
  };
};
