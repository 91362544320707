export const isDevApi = process.env.REACT_APP_BUILD === 'development';
export const isStageApi = process.env.REACT_APP_BUILD === 'stage';
export const isProdApi = process.env.REACT_APP_BUILD === 'production';

export const BASE_URL = isProdApi
  ? 'https://catalog.breezebot.ru/api'
  : isStageApi
  ? 'https://breeze-bot-catalog-stage.bots.winsolutions.ru/api'
  : 'https://breeze-bot-catalog-dev.bots.winsolutions.ru/api';

export const BASE_URL_SEND_FIRST = isProdApi
  ? 'https://api.breezebot.ru/'
  : isStageApi
  ? 'https://breeze-bot-telegramapi-stage.bots.winsolutions.ru/'
  : 'https://breeze-bot-telegramapi-dev.bots.winsolutions.ru/';
