import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductsType } from 'src/config/types';
import ShopService from 'src/services/ShopService';

export const fetchProducts = createAsyncThunk(
  'products/fetchAll',
  async (params: getProductsType, thunkAPI) => {
    try {
      const response = await ShopService.getProductsByShopId(params);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить товары');
    }
  },
);
