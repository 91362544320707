import { FC } from 'react';
import { useTotals } from 'src/hooks/useTotals';
import { numberWithSpaces } from 'src/utils';

import cn from 'classnames';

export const TotalsList: FC = () => {
  const { totalProductsCount, totalPrice, totalBenefit } = useTotals();

  const styles = 'flex pr-3 justify-between font-gilroy-400 text-xs';

  const list = [
    {
      name: 'Товаров в заказе',
      value: numberWithSpaces(totalProductsCount),
      styles: 'border-t border-grey-200 pt-2',
    },
    {
      name: 'Товаров на сумму',
      value: numberWithSpaces(+totalPrice + +totalBenefit) + ` ₽`,
    },
    // {
    //   name: 'Доставка',
    //   value: 199 + ` ₽`,
    // },
    {
      name: 'Скидка',
      value: +totalBenefit ? numberWithSpaces(totalBenefit) + ` ₽` : null,
      styles: 'text-pink-100',
    },
  ];

  return (
    <div className="w-full flex flex-col gap-2">
      {list.map(el =>
        el.value ? (
          <div className={cn(styles, el.styles)}>
            <div>{el.name}</div>
            <div>{el.value}</div>
          </div>
        ) : null,
      )}

      <div
        className={
          'flex pr-3 justify-between font-gilroy-600 text-sm border-t border-grey-200 pt-1.5'
        }
      >
        <div>Итого:</div>
        <div>{numberWithSpaces(totalPrice) + ` ₽`}</div>
      </div>
    </div>
  );
};
