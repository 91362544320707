import { API } from 'src/services/API';
import { useTelegram } from 'src/hooks/useTelegram';
import { getProductsType } from 'src/config/types';
import qs from 'qs';
import { numberToString } from 'src/utils';

const getShopById = async (shopId: string) => {
  const { chatId } = useTelegram();

  return await API.get(
    `/Shop/Nearby?shopId=${shopId}&latitude=${0}&longitude=${0}&chatId=${chatId}`,
  );
};

const getShopFiltersById = async (shopId: string) => {
  const { chatId } = useTelegram();

  return await API.get(`/Catalog/Filters/?shopId=${shopId}&chatId=${chatId}`);
};

const getProductsByShopId = async (params: getProductsType) => {
  const { chatId } = useTelegram();
  const { sale, price, sort, searchString, groupIds, shopId, subGroupIds } = params;

  const queryObjects = {
    ...{ ...(sale && { discountMin: numberToString(sale?.min) }) },
    ...{ ...(sale && { discountMax: numberToString(sale?.max) }) },
    ...{ ...(price && { priceMin: numberToString(price?.min) }) },
    ...{ ...(price && { priceMax: numberToString(price?.max) }) },
    sort: `${sort?.type || 'name'}|${sort?.order || 'asc'}`,
    ...{ ...(searchString && { filter: searchString }) },
    ...{ ...(groupIds && { groupIds }) },
    ...{ ...(subGroupIds && { subGroupIds }) },
  };
  return await API.get(
    `/Catalog/Groups/Goods?shopId=${shopId}&chatId=${chatId}&${qs.stringify(queryObjects)}`,
  );
};

const ShopService = { getShopById, getShopFiltersById, getProductsByShopId };

export default ShopService;
