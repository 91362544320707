import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsState } from './types';
import { fetchProducts } from './actions';
import { IProduct } from 'src/models/IProduct';

const initialState: ProductsState = {
  products: [],
  isLoadingProducts: true,
  error: '',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProducts: state => {
      state.products = [];
    },
  },
  extraReducers: {
    [fetchProducts.pending.type]: state => {
      state.isLoadingProducts = true;
      state.products = [];
    },
    [fetchProducts.fulfilled.type]: (state, action: PayloadAction<IProduct[]>) => {
      state.isLoadingProducts = false;
      state.error = '';
      state.products = action.payload;
    },
    [fetchProducts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingProducts = false;
      state.error = action.payload;
    },
  },
});

export const { resetProducts } = productsSlice.actions;

const reducer = productsSlice.reducer;
export default reducer;
