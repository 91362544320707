import React, { FC, useRef } from 'react';

import cn from 'classnames';

interface TextAreaProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  classNames?: string;
}

export const TextArea: FC<TextAreaProps> = ({
  value,
  defaultValue,
  onChange,
  placeholder,
  classNames,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <textarea
      maxLength={200}
      ref={ref}
      rows={2}
      className={cn(
        {
          'bg-white': Boolean(ref?.current?.value),
          'bg-transparent': !Boolean(ref?.current?.value),
        },
        'p-4 w-full border border-black rounded-xl placeholder:text-black text-sm',
        'font-gilroy-400 focus:bg-white resize-none',
        classNames,
      )}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      placeholder={placeholder || ''}
    />
  );
};
