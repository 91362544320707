import React, { FC, useCallback, useState } from 'react';
import { BackButton } from 'src/components/BackButton';
import { Button } from 'src/components/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { MultiRangeSlider } from 'src/components/MultiRangeSlider';
import { setTempPrice, setTempSale } from 'src/store/filter';
import { FilterState, Interval } from 'src/store/filter/types';

export const FilterPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { filters, categories, shops } = useAppSelector(state => state);

  const [tempFilters, setTempFilter] = useState<FilterState>(filters);

  const handleChangeSaleFilter = (tempSale: Interval) => {
    setTempFilter(prev => ({ ...prev, tempSale }));
  };

  const handleChangePriceFilter = (tempPrice: Interval) => {
    setTempFilter(prev => ({ ...prev, tempPrice }));
  };

  const handleSubmit = useCallback(
    (reset?: boolean) => {
      dispatch(setTempSale(reset ? filters.initSale : tempFilters.tempSale));
      dispatch(setTempPrice(reset ? filters.initPrice : tempFilters.tempPrice));

      navigate(-1);
    },
    [tempFilters, categories, shops],
  );

  const handleReset = () => {
    handleChangeSaleFilter(filters.initSale);
    handleChangePriceFilter(filters.initPrice);
    handleSubmit(true);
  };

  return (
    <div className="py-4 flex flex-col gap-4 h-full">
      <div className="px-4 w-full flex items-center justify-between">
        <BackButton text="Фильтры" />
      </div>

      <div className="px-4 relative">
        <div className="font-gilroy-500 text-sm mb-5">Цена</div>
        <MultiRangeSlider
          values={{ min: tempFilters.tempPrice.min, max: tempFilters.tempPrice.max }}
          initValues={{ min: filters.initPrice.min, max: filters.initPrice.max }}
          onChange={handleChangePriceFilter}
          valueType="₽"
        />
      </div>

      <div className="px-4">
        <div className="font-gilroy-500 text-sm mb-5">Скидка</div>
        <MultiRangeSlider
          values={{ min: tempFilters.tempSale.min, max: tempFilters.tempSale.max }}
          initValues={{ min: filters.initSale.min, max: filters.initSale.max }}
          onChange={handleChangeSaleFilter}
          valueType="%"
        />
      </div>

      <div className="w-full px-4 mt-auto">
        <Button
          text="Сбросить"
          variant="outline"
          classNames="w-full h-12 mb-4"
          onClick={handleReset}
        />
        <Button text="Применить" variant="fill" classNames="w-full h-12" onClick={handleSubmit} />
      </div>
    </div>
  );
};
