import { FC, useState } from 'react';
import { StatType } from 'src/config/types';

import cn from 'classnames';

interface StatProps {
  stat: StatType;
  classNames?: string;
}

export const Stat: FC<StatProps> = ({ stat, classNames }) => {
  const [selected, setSelected] = useState<number>(0);

  return (
    <div className={cn('', classNames)}>
      <div className="mb-1.5 font-gilroy-500 truncate text-xs">{stat.parameterName}</div>
      <div className="flex gap-1 flex-wrap">
        {stat.parameterValues.map((item, i) => (
          <div
            key={`stat-${stat.parameterId}-${i}`}
            className="flex flex-col items-center gap-1.5"
            onClick={() => setSelected(i)}
          >
            <div
              className={cn(
                {
                  'border-transparent cursor-pointer': selected !== i,
                  'border-pink-100 cursor-default': selected === i,
                  'w-8': Boolean(item?.image),
                },
                'flex items-center justify-center bg-white rounded-full overflow-hidden',
                'h-8 min-w-[2rem] border transition-full',
              )}
            >
              {item?.image ? (
                <img className="w-full h-full object-contain" src={item.image} alt="" />
              ) : null}
              {!item?.image && item?.valueName ? (
                <div className="font-gilroy-400 text-xs px-2.5 whitespace-nowrap">
                  {item.valueName}
                </div>
              ) : null}
            </div>
            {item?.valueName && item?.image ? (
              <div className="font-gilroy-500 text-extraSmall text-grey-100">{item.valueName}</div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};
