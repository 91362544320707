import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState, Interval, SortType, SubGroupType } from './types';
import { fetchShopFilters } from './actions';
import { IShopFilter } from 'src/models/IShopFilter';
import { stringToNumber } from 'src/utils';

export const initialState: FilterState = {
  initSale: {} as Interval,
  tempSale: {} as Interval,
  initPrice: {} as Interval,
  tempPrice: {} as Interval,
  sort: {
    type: 'price',
    order: 'asc',
  },
  subGroupsFilter: [] as SubGroupType[],
  searchString: '',
  isLoadingFilters: true,
  error: '',
};

const filtersSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterState>) => {
      state.tempPrice = action.payload.tempPrice;
      state.tempSale = action.payload.tempSale;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setTempSale: (state, action: PayloadAction<Interval>) => {
      state.tempSale = action.payload;
    },
    setTempPrice: (state, action: PayloadAction<Interval>) => {
      state.tempPrice = action.payload;
    },
    setSort: (state, action: PayloadAction<SortType>) => {
      state.sort = action.payload;
    },
    resetFilter: state => {
      state.tempPrice = state.initPrice;
      state.tempSale = state.initSale;
    },
    setSubGroupsFilter: (state, action: PayloadAction<SubGroupType[]>) => {
      state.subGroupsFilter = action.payload;
    },
  },
  extraReducers: {
    [fetchShopFilters.pending.type]: state => {
      state.isLoadingFilters = true;
    },
    [fetchShopFilters.fulfilled.type]: (state, action: PayloadAction<IShopFilter>) => {
      const { priceMin, priceMax, discountMin, discountMax } = action.payload;
      state.isLoadingFilters = false;
      state.error = '';
      state.initSale = {
        min: stringToNumber(discountMin),
        max: stringToNumber(discountMax) || 100,
      };
      state.tempSale = {
        min: stringToNumber(discountMin),
        max: stringToNumber(discountMax) || 100,
      };
      state.initPrice = {
        min: Math.trunc(stringToNumber(priceMin)),
        max: Math.trunc(stringToNumber(priceMax)) + 1,
      };
      state.tempPrice = {
        min: Math.trunc(stringToNumber(priceMin)),
        max: Math.trunc(stringToNumber(priceMax)) + 1,
      };
    },
    [fetchShopFilters.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingFilters = false;
      state.error = action.payload;
    },
  },
});

export const {
  setFilters,
  setTempSale,
  setTempPrice,
  setSearchString,
  resetFilter,
  setSubGroupsFilter,
  setSort,
} = filtersSlice.actions;

const reducer = filtersSlice.reducer;
export default reducer;
