import { SendBasketReqData } from 'src/API';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProduct } from 'src/models/IProduct';
import { IShop } from 'src/models/IShop';
import BasketService from 'src/services/BasketService';

export const fetchBasket = createAsyncThunk('basket/fetchAll', async (shop: IShop, thunkAPI) => {
  try {
    const res = await BasketService.getBasketById(shop.apiId);
    const products: IProduct[] = res.data;

    // if (products.length) {
    //   await postBasketReq({ products, shopId: shop.apiId });
    // }

    return { products, shopType: shop.typeId };
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить корзину');
  }
});

export const clearBasket = createAsyncThunk('basket/clear', async (shop: IShop, thunkAPI) => {
  try {
    await BasketService.clearBasketById(shop.apiId);

    return shop.typeId;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось очистить корзину');
  }
});

export const setProductToBasket = createAsyncThunk(
  'basket/addProductToBasket',
  async ({ products, shop }: { products: IProduct[]; shop: IShop }, thunkAPI) => {
    try {
      await BasketService.addProductToBasket(products, shop.apiId);

      return { product: products[0], shopType: shop.typeId };
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось обновить продукт');
    }
  },
);

export const postBasketIsSend = createAsyncThunk(
  'basket/postBasketIsSend',
  async ({ products, shopId }: { products: IProduct[]; shopId: string }, thunkAPI) => {
    try {
      await BasketService.sendBasket(products, shopId);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось обновить продукт');
    }
  },
);

export const sendBasketToChat = createAsyncThunk(
  'basket/setBasketToChat',
  async (
    {
      resData,
      shopId,
      commentary,
    }: { resData: SendBasketReqData[]; shopId: string; commentary: string },
    thunkAPI,
  ) => {
    try {
      await BasketService.sendBasketToChat(resData, shopId, commentary);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось отправить корзину в чат');
    }
  },
);
