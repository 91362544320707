import { FC, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'src/hooks/redux';
import { isTouchEnabled } from 'src/utils';

import { ReactComponent as SmallArrowIcon } from 'src/assets/icons/kit/arrow-small.svg';

import cn from 'classnames';

interface ImagesCarouselProps {
  imagesList: string[];
  classNames?: string;
}

export const ImagesCarousel: FC<ImagesCarouselProps> = ({ imagesList, classNames }) => {
  const {
    common: { settings },
  } = useAppSelector(state => state);

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [active, setActive] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref?.current?.offsetWidth || 0);
    }
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const swipe = (active: number) => {
    if (sliderRef?.current) {
      (sliderRef.current as HTMLDivElement).style.transform = `translateX(-${active * width}px)`;
    }

    if (controlRef?.current) {
      (controlRef.current as HTMLDivElement).style.transform = `translateX(${
        active * (width / imagesList.length)
      }px)`;
    }
  };

  const handleNext = () => {
    const nextActive = active === imagesList.length - 1 ? 0 : active + 1;
    swipe(nextActive);
    setActive(nextActive);
  };
  const handlePrev = () => {
    const nextActive = active === 0 ? imagesList.length - 1 : active - 1;
    swipe(nextActive);
    setActive(nextActive);
  };

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 80) {
      handleNext();
    }

    if (touchStart - touchEnd < -80) {
      handlePrev();
    }
  };

  const handleNumber = i => {
    swipe(i);
    setActive(i);
  };

  const styles =
    'absolute top-1/2 cursor-pointer w-6 h-6 rounded-full bg-white cursor-pointer ' +
    'flex items-center justify-center';

  return (
    <div ref={ref} className={cn('relative', classNames)}>
      <div
        className="h-full w-full flex transition-full"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        ref={sliderRef}
      >
        {imagesList.map((image, i) => (
          <div key={`carousel-image-${i}`} className="w-full h-full min-w-full">
            <img src={image} alt="" className="w-full h-full object-contain" />
          </div>
        ))}
      </div>

      {!isTouchEnabled() ? (
        <>
          {active !== 0 ? (
            <div className={cn(styles, 'left-2')} onClick={handlePrev}>
              <SmallArrowIcon
                className="w-3 h-3 -rotate-90 mr-0.5"
                style={{ stroke: settings.buttonsColor }}
              />
            </div>
          ) : null}
          {active !== imagesList?.length - 1 ? (
            <div className={cn(styles, 'right-2')} onClick={handleNext}>
              <SmallArrowIcon
                className="w-3 h-3 rotate-90 ml-0.5"
                style={{ stroke: settings.buttonsColor }}
              />
            </div>
          ) : null}
        </>
      ) : null}

      {imagesList?.length > 1 ? (
        <div className="w-full flex absolute bottom-2 left-0 justify-center">
          <div className="flex gap-1.5">
            {imagesList.map((_, i) => (
              <div
                onClick={() => handleNumber(i)}
                key={`carousel-image-${i}`}
                className="w-2.5 h-2.5 rounded-full cursor-pointer transition-full"
                style={{
                  backgroundColor: active === i ? settings.buttonsColor : settings.textColor,
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
      <div className="w-full bg-white"></div>
    </div>
  );
};
