import { FC } from 'react';
import { IProduct } from 'src/models/IProduct';
import { numberWithSpaces, stringToNumber } from 'src/utils';
import { ProductCounter } from 'src/components/ProductCounter';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  product: IProduct;
}

export const ProductCard: FC<ProductCardProps> = ({ product }) => {
  return (
    <Link
      to={`/product/${product.id}${window.location.search}`}
      className="w-full bg-white relative rounded-lg overflow-hidden"
    >
      {product.discount && product.discount.toString() !== '0' && product.discount ? (
        <div
          className={
            'p-1 bg-pink-100 absolute left-0 top-4 rounded-r font-gilroy-700 text-xs text-white'
          }
        >
          -{product.discount}%
        </div>
      ) : null}
      <div className="w-full h-28">
        <img className="w-full h-full object-contain" alt="" src={product.image} />
      </div>
      <div className="w-full p-2.5">
        <div className="w-full font-gilroy-500 text-xs text-black-200 line-clamp-2 min-h-[2rem]">
          {product.name}
        </div>
        <div
          className={
            'text-grey-100 font-gilroy-500 text-extraSmall h-5 line-clamp-2 mb-2.5 min-h-[1.25rem]'
          }
        >
          {product.description}
        </div>
      </div>
      <div className="px-2.5 pb-2.5">
        <div className="mb-2.5">
          <div className="text-pink-100 font-gilroy-600 text-lg-1 mb-0.5">
            {`${numberWithSpaces(product.newPrice)} ${product?.currency || '₽'}`}
          </div>
          {+product.discount > 0 ||
          +stringToNumber(product.oldPrice) > +stringToNumber(product.newPrice) ? (
            <div className="text-grey-300 h-2.5 text-extra-xs-1 font-gilroy-600 line-through">
              {`${numberWithSpaces(product.oldPrice)} ${product?.currency || '₽'}`}
            </div>
          ) : null}
        </div>
        <ProductCounter product={product} classNames="" />
      </div>
    </Link>
  );
};
