import { createAsyncThunk } from '@reduxjs/toolkit';
import SettingsService from 'src/services/SettingsService';

export const fetchSettings = createAsyncThunk(
  'common/fetchAll',
  async (shopId: string, thunkAPI) => {
    try {
      const response = await SettingsService.getShopSettingsById(shopId);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить настройки магазина');
    }
  },
);
