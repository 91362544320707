import { CategoryProps } from './types';
import { ICategory } from 'src/models/ICategory';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCategories, fetchSubGroups } from './actions';
import { SubGroupType } from 'src/store/filter/types';

const initialState: CategoryProps = {
  selectedCategory: {} as ICategory,
  categories: [],
  isLoadingCategories: true,
  subGroups: [],
  isLoadingSubGroups: true,
  error: '',
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setSelectedCategory: (state, action: PayloadAction<ICategory>) => {
      state.selectedCategory = action.payload;
    },
    resetCategory: state => {
      state.selectedCategory = {} as ICategory;
      state.categories = [];
    },
  },
  extraReducers: {
    [fetchCategories.pending.type]: state => {
      state.isLoadingCategories = true;
    },
    [fetchCategories.fulfilled.type]: (state, action: PayloadAction<ICategory[]>) => {
      state.isLoadingCategories = false;
      state.error = '';
      state.categories = action.payload.sort((a: ICategory, b: ICategory) => a?.id - b?.id);
    },
    [fetchCategories.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingCategories = false;
      state.error = action.payload;
    },
    [fetchSubGroups.pending.type]: state => {
      state.isLoadingSubGroups = true;
    },
    [fetchSubGroups.fulfilled.type]: (state, action: PayloadAction<SubGroupType[]>) => {
      state.isLoadingSubGroups = false;
      state.error = '';
      state.subGroups = action.payload?.length
        ? action.payload.filter(el => !!el?.name)
        : ([] as SubGroupType[]);
    },
    [fetchSubGroups.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSubGroups = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedCategory, resetCategory } = categoriesSlice.actions;

const reducer = categoriesSlice.reducer;
export default reducer;
