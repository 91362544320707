import React, { FC, useEffect, useMemo, useState } from 'react';
import { BackButton } from 'src/components/BackButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { fetchOrders } from 'src/store/orders/actions';
import { Order } from 'src/components/Order';
import { orderTypesData } from 'src/config/data';
import cn from 'classnames';

export const OrdersPage: FC = () => {
  const {
    shops: { shop },
    orders: { orders, isLoadingOrders },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const [ordersType, setOrdersType] = useState<'current' | 'archive' | 'canceled'>('current');

  useEffect(() => {
    (async () => {
      if (isLoadingOrders && shop?.apiId) {
        await dispatch(fetchOrders(shop?.apiId));
      }
    })();
  }, [isLoadingOrders, shop]);

  const filteredOrder = useMemo(
    () => orders?.[ordersType]?.filter(order => order.orderHistory.length) || [],
    [orders, ordersType],
  );

  const handleClick = (key: 'current' | 'archive' | 'canceled') => {
    const newOrders = orders?.[key]?.filter(order => order.orderHistory.length) || [];
    if (!newOrders?.length) {
      return;
    }

    setOrdersType(key);
  };

  return (
    <div className="py-4 flex min-h-full flex-col gap-4">
      <div className="px-4 w-full flex items-center justify-between">
        <BackButton text="Заказы" />
      </div>

      <div className="flex gap-4 px-4">
        {Object.keys(orderTypesData).map(key => (
          <div
            onClick={() => handleClick(key as 'current' | 'archive' | 'canceled')}
            key={`order-type-${key}`}
            className={cn(
              { 'opacity-50': key !== ordersType, 'border-b border-pink-100': key == ordersType },
              'font-gilroy-500 text-sm',
            )}
          >
            {orderTypesData[key]}
          </div>
        ))}
      </div>

      {!isLoadingOrders && !filteredOrder?.length ? (
        <div className="px-4 w-full text-center text-black-100 font-gilroy-500 mt-10">
          У вас нет заказов
        </div>
      ) : null}

      <div className="px-4">
        {filteredOrder.map(order => (
          <Order key={`order-${order.id}`} order={order} />
        ))}
      </div>
    </div>
  );
};
