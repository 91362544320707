import { FC, ReactNode, useEffect } from 'react';
import { Head } from 'src/components/Head';
import { Loader } from 'src/components/Loader';
import { useAppSelector } from 'src/hooks/redux';
import { defaultColor } from 'src/config/data';

interface MainContainerProps {
  children?: ReactNode;
}

export const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const {
    common: {
      settings: { color },
      isLoadingSettings,
    },
  } = useAppSelector(state => state);

  useEffect(() => {
    document.body.style.backgroundColor = color || defaultColor;
  }, [color]);

  if (isLoadingSettings) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader classNames="w-1/2" />
      </div>
    );
  }

  return (
    <>
      <Head />
      <div className="pt-16 h-full w-full">{children}</div>
    </>
  );
};
