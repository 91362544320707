import axios from 'axios';
import { BASE_URL } from 'src/config';

export const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((req: any) => {
  req.headers.CatalogToken = '1045b901-aa30-452e-938b-8d5e56c6001c';
  return req;
});
