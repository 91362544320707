import { FC, useMemo } from 'react';
import { IProduct } from 'src/models/IProduct';
import { setProductToBasket } from 'src/store/basket/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';

import { ReactComponent as PlusIcon } from 'src/assets/icons/kit/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/kit/minus.svg';
import cn from 'classnames';

interface ProductCounterProps {
  big?: boolean;
  product: IProduct;
  classNames?: string;
}

export const ProductCounter: FC<ProductCounterProps> = ({ big, product, classNames }) => {
  const dispatch = useAppDispatch();
  const {
    basket: { basketProducts },
    products: { products },
    shops: { shop },
    common: { settings },
  } = useAppSelector(state => state);

  const amount = useMemo(() => {
    if (!basketProducts?.[shop.typeId]) {
      return product.amount;
    }
    const productFromBasket = basketProducts[shop.typeId].find(item => item.id === product.id);

    if (productFromBasket) {
      return productFromBasket.amount;
    }

    return product.amount;
  }, [basketProducts, product, products, setProductToBasket, setProductToBasket, shop]);

  const handleSetProductToBasket = (newProduct: IProduct) => {
    dispatch(setProductToBasket({ products: [newProduct], shop }));
  };

  const handleAddToBasket = e => {
    e.preventDefault();
    const newProduct = { ...product, amount: (amount || 0) + 1 };

    handleSetProductToBasket(newProduct);
  };

  const handleRemoveFromBasket = e => {
    e.preventDefault();
    const newProduct = { ...product, amount: (amount || 0) - 1 };
    handleSetProductToBasket(newProduct);
  };

  const styles = 'w-1/3 h-full flex items-center justify-center';

  return (
    <div
      className={cn(
        { 'h-7 max-w-[10rem] rounded-md': !Boolean(big), 'h-12 rounded-full': Boolean(big) },
        'border w-full flex items-center overflow-hidden',
        classNames,
      )}
      style={{
        borderColor: settings.buttonsColor,
        backgroundColor: amount ? '#FFFFFF' : settings.buttonsColor,
      }}
    >
      {!amount ? (
        <div
          className={cn(
            { 'text-xs': !Boolean(big) },
            'font-gilroy-600 w-full h-full flex items-center justify-center',
          )}
          onClick={handleAddToBasket}
          style={{
            color: settings.textColor,
          }}
        >
          В корзину
        </div>
      ) : null}
      {amount ? (
        <>
          {/*<div*/}
          {/*  className={cn(*/}
          {/*    { 'w-1/4': !Boolean(big), 'w-1/2': Boolean(big) },*/}
          {/*    'h-full flex items-center justify-center',*/}
          {/*  )}*/}
          {/*  style={{*/}
          {/*    backgroundColor: settings.buttonsColor,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ShoppingCartIcon*/}
          {/*    className="w-5 h-5"*/}
          {/*    style={{*/}
          {/*      fill: settings.textColor,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={cn('w-full flex h-full ')}>
            <div className={cn('cursor-pointer', styles)} onClick={handleRemoveFromBasket}>
              <MinusIcon className="w-3 h-3" style={{ fill: settings.buttonsColor }} />
            </div>
            <div
              className={cn({ 'text-sm': !Boolean(big) }, 'font-gilroy-600', styles)}
              style={{ color: settings.buttonsColor }}
            >
              {amount}
            </div>
            <div className={cn('cursor-pointer', styles)} onClick={handleAddToBasket}>
              <PlusIcon className="w-3 h-3" style={{ fill: settings.buttonsColor }} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
