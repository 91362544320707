import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Input } from 'src/components/Input';
import { ProductCard } from 'src/components/Cards/ProductCard';
import { BackButton } from 'src/components/BackButton';
import { SubGroupsList } from 'src/components/SubGroupsList';
import { Loader } from 'src/components/Loader';
import { setSearchString, setSort } from 'src/store/filter';
import { fetchProducts } from 'src/store/products/actions';
import { debounce } from 'src/utils';
import { fetchSubGroups } from 'src/store/categories/actions';
import { Link } from 'react-router-dom';
import { defaultButtonsColor } from 'src/config/data';
import { getProductsType } from 'src/config/types';
import { SortType } from 'src/store/filter/types';

import { ReactComponent as FilterIcon } from 'src/assets/icons/kit/filter.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';

import cn from 'classnames';

export const CatalogPage: FC = () => {
  const { groupId } = useParams();
  const {
    shops: { shop },
    products: { products, isLoadingProducts },
    categories: { categories, selectedCategory, isLoadingSubGroups, subGroups },
    common: { settings },
    filters,
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const group = useMemo(
    () => categories.find(group => group.id.toString() === groupId),
    [categories, groupId],
  );

  const filterCount = useMemo(() => {
    let count = 0;
    if (JSON.stringify(filters.tempSale) !== JSON.stringify(filters.initSale)) {
      count += 1;
    }
    if (JSON.stringify(filters.tempPrice) !== JSON.stringify(filters.initPrice)) {
      count += 1;
    }
    return count;
  }, [filters]);

  const handleSort = async () => {
    const newSort: SortType = {
      type: 'price',
      order: filters.sort.order === 'asc' ? 'desc' : 'asc',
    };

    dispatch(setSort(newSort));
  };

  const handleSearch = debounce(async (str: string) => {
    dispatch(setSearchString(str));
  }, 400);

  useEffect(() => {
    (async () => {
      if (groupId && shop.apiId) {
        await dispatch(fetchSubGroups({ shopId: shop.apiId, groupId }));
      }
    })();
  }, [shop, groupId]);

  useEffect(() => {
    (async () => {
      if (groupId?.includes('search_') && !filters.searchString) {
        const search = groupId.replace('search_', '');
        dispatch(setSearchString(search));
      }

      if (filters.initPrice.max) {
        const params: getProductsType = {
          price: filters.tempPrice,
          sale: filters.tempSale,
          sort: filters.sort,
          searchString: filters.searchString,
          groupIds:
            (selectedCategory?.id || '').toString() ||
            (!groupId?.includes('search_') ? groupId?.toString() || '' : ''),
          subGroupIds: filters.subGroupsFilter.map(subGroup => subGroup.id.toString()).join(','),
          shopId: shop?.apiId,
        };

        await dispatch(fetchProducts(params));
      }
    })();
  }, [filters, shop]);

  return (
    <div className="py-4 flex flex-col gap-4">
      <div className="px-4 w-full flex items-center justify-between">
        <BackButton text={group?.name || 'Группы'} onClick={() => handleSearch('')} href="/" />
      </div>

      {isLoadingSubGroups ? <Loader classNames="mx-auto w-[2.125rem]" /> : null}
      {!isLoadingSubGroups && subGroups?.length ? <SubGroupsList /> : null}

      <Input
        defaultValue={
          groupId?.includes('search_') ? groupId.replace('search_', '') : filters.searchString
        }
        onChange={handleSearch}
        classNames="mx-4 min-h-[3rem]"
        placeholder="Поиск"
      />

      <div className="flex items-center justify-between mx-4">
        <Link to={`/filter${window.location.search}`} className="flex items-center gap-3">
          <div className="w-5 h-5 relative">
            <FilterIcon className="w-full h-full" />
            <div
              className={
                'w-3 h-3 rounded-full text-white font-gilroy-700 text-extraSmall flex ' +
                'items-center justify-center absolute bottom-0 -right-1'
              }
              style={{ backgroundColor: settings?.buttonsColor || defaultButtonsColor }}
            >
              {filterCount}
            </div>
          </div>
          <div className="text-black-200 font-gilroy-400 text-xs">Фильтры</div>
        </Link>

        <div className="flex items-center gap-2" onClick={handleSort}>
          <ArrowIcon
            className={cn(
              {
                'rotate-90': filters.sort.order === 'asc',
                '-rotate-90': filters.sort.order === 'desc',
              },
              'w-4 h-4',
            )}
          />
          <div className="font-gilroy-400 text-xs">{`Цена по ${
            filters.sort.order === 'asc' ? 'возрастанию' : 'убыванию'
          }`}</div>
        </div>
      </div>

      {isLoadingProducts ? <Loader classNames="w-1/3 mx-auto mt-20" /> : null}

      {!isLoadingProducts ? (
        <div className="grid grid-cols-2 gap-4 px-4">
          {products.map(product => (
            <ProductCard key={`products-list-${product.id}`} product={product} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
