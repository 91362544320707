import React, { FC, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useTelegram } from './hooks/useTelegram';
import { useAppDispatch } from './hooks/redux';
import { getInitialData } from './utils';
import { setShopId } from 'src/store/common';
import { setShop } from 'src/store/shops';
import { MainContainer } from 'src/containers/MainContainer';
import { fetchSettings } from './store/common/actions';
import { CategoriesPage } from 'src/pages/CategoriesPage';
import { CatalogPage } from 'src/pages/CatalogPage';
import { FilterPage } from 'src/pages/FilterPage';
import { BasketPage } from 'src/pages/BasketPage';
import { ProductPage } from 'src/pages/ProductPage';
import { OrdersPage } from 'src/pages/OrdersPage';
import ShopService from 'src/services/ShopService';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { onExpand, disableClose } = useTelegram();

  useEffect(() => {
    onExpand();
    disableClose();
    const urlParams = new URLSearchParams(window.location.search);
    const shopId = urlParams.get('shopId');

    if (!shopId) {
      return;
    }

    dispatch(setShopId(shopId));

    (async () => {
      await dispatch(fetchSettings(shopId));
      const response = await ShopService.getShopById(shopId);

      if (response?.data?.length) {
        const shop = response?.data?.[0];

        dispatch(setShop(shop));
        await getInitialData(shop, dispatch);
      }
    })();
  }, []);

  return (
    <BrowserRouter>
      <MainContainer>
        <Routes>
          <Route path="/" element={<CategoriesPage />} />
          <Route path="/catalog/:groupId" element={<CatalogPage />} />
          <Route path="/product/:productId" element={<ProductPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/filter" element={<FilterPage />} />
          <Route path="/basket" element={<BasketPage />} />
          <Route path="/orders" element={<OrdersPage />} />
        </Routes>
      </MainContainer>
    </BrowserRouter>
  );
};

export default App;
