import React from 'react';
import ReactDOM from 'react-dom/client';
import { setupStore } from 'src/store/store';
import { Provider } from 'react-redux';
import App from './App';

import './input.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

const store = setupStore();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
);
