import { createAsyncThunk } from '@reduxjs/toolkit';
import OrdersService from 'src/services/OrdersService';

export const fetchOrders = createAsyncThunk('orders/fetchAll', async (shopId: string, thunkAPI) => {
  try {
    const current = await OrdersService.getOrders(shopId, 'current');
    const archive = await OrdersService.getOrders(shopId, 'archive');
    const canceled = await OrdersService.getOrders(shopId, 'canceled');

    return {
      current: current.data || [],
      archive: archive.data || [],
      canceled: canceled.data || [],
    };
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить заказы');
  }
});
