import { FC } from 'react';
import { useAppSelector } from 'src/hooks/redux';

import cn from 'classnames';

interface LoaderProps {
  classNames?: string;
}

export const Loader: FC<LoaderProps> = ({ classNames }) => {
  const {
    settings: { buttonsColor },
  } = useAppSelector(state => state.common);

  return (
    <div className={cn('relative', classNames)}>
      <svg className="before:content-[''] before:block before:pt-[100]" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="3"
          strokeMiterlimit="10"
          style={{ stroke: buttonsColor }}
        />
      </svg>
    </div>
  );
};
