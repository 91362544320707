import React, { FC, useState } from 'react';
import { IOrder } from 'src/models/IOrder';
import { monthsNames, orderStatuses } from 'src/config/data';

import { ReactComponent as ArrowSmallIcon } from 'src/assets/icons/kit/arrow-small.svg';
import cn from 'classnames';

interface OrderProps {
  order: IOrder;
}

export const Order: FC<OrderProps> = ({ order }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const lastChange = order?.orderHistory?.[0];

  const handleOpen = () => setIsOpen(prev => !prev);

  const getDate = (dateInString: string) => {
    const date = new Date(dateInString);

    return `${date.getDate()} ${monthsNames[date.getMonth()]} ${
      date.getHours() < 10 ? '0' : ''
    }${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  };

  const getItemDate = (dateInString: string) => {
    const date = new Date(dateInString);

    return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${
      date.getMonth() < 10 ? '0' : ''
    }${date.getMonth()}.${date.getFullYear()} г. ${
      date.getHours() < 10 ? '0' : ''
    }${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  };

  return (
    <div className="p-3 border-b border-grey-200">
      <div className="flex justify-between items-center cursor-pointer" onClick={handleOpen}>
        <div>
          <div className="text-extra-xs font-gilroy-400">{`Заказ ${order.id}`}</div>
          <div className="font-gilroy-600 text-sm">{orderStatuses[lastChange?.statusId]}</div>
          <div className="text-green-100 font-gilroy-400 text-xs">
            {getDate(lastChange?.creationDateTime)}
          </div>
        </div>
        <ArrowSmallIcon className={cn({ 'rotate-180': !isOpen }, 'w-2.5 h-2.5')} />
      </div>

      {isOpen ? (
        <div className="pt-3 gap-8 flex flex-col">
          {order.orderHistory.map((history, i) => (
            <div
              key={`order-${order.id}-history-${history.id}`}
              className="flex gap-2 items-center relative"
            >
              {i !== order.orderHistory?.length - 1 ? (
                <div className="absolute left-5 top-full border-l border-black h-8 border-dashed" />
              ) : null}
              <div
                className={
                  'flex items-center justify-center font-gilroy-600 text-sm p-3 rounded-full ' +
                  'border border-black w-10 h-10'
                }
              >
                {order.orderHistory.length - i}
              </div>
              <div>
                <div className="font-gilroy-400 text-sm">{orderStatuses[history.statusId]}</div>
                <div className="font-gilroy-400 text-extra-xs">
                  {getItemDate(history.creationDateTime)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
