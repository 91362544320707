import { useTelegram } from 'src/hooks/useTelegram';
import { API } from 'src/services/API';
import { IProduct } from 'src/models/IProduct';
import { SendBasketReqData } from 'src/API';
import { SecondAPI } from 'src/services/SecondAPI';

const getBasketById = async (shopId: string) => {
  const { queryId, chatId } = useTelegram();

  return await API.get(
    `/Catalog/Basket/State/?shopApiId=${shopId}&chatId=${chatId}&queryId=${queryId}`,
  );
};

const clearBasketById = async (shopId: string) => {
  const { queryId, chatId } = useTelegram();

  return await API.post(
    `/Catalog/Basket/Clear/?shopId=${shopId}&chatId=${chatId}&queryId=${queryId}`,
  );
};

const getProductsList = (products: IProduct[], shopId: string, isSend: boolean) =>
  products.map(product => ({
    goodCode: product.id,
    groupId: product.groupId,
    price: product.newPrice,
    discount: product.discount,
    image: product.image,
    amount: product.amount,
    oldPrice: product.oldPrice,
    goodName: product.name,
    shopApiId: shopId,
    send: isSend,
  }));

const addProductToBasket = async (products: IProduct[], shopId: string) => {
  const { queryId, chatId } = useTelegram();

  const resBasket = getProductsList(products, shopId, false);

  return await API.post(
    `/Catalog/Basket/State/?chatId=${chatId}&queryId=${queryId}&shopId=${shopId}`,
    resBasket,
  );
};

const sendBasket = async (products: IProduct[], shopId: string) => {
  const { queryId, chatId } = useTelegram();

  const resBasket = getProductsList(products, shopId, true);

  return await API.post(
    `/Catalog/Basket/State/?chatId=${chatId}&queryId=${queryId}&shopId=${shopId}`,
    resBasket,
  );
};

export const sendBasketToChat = async (
  resData: SendBasketReqData[],
  shopId: string,
  commentary: string,
) => {
  const { queryId, chatId } = useTelegram();

  return await SecondAPI.post(
    `/Catalog/SendGoods/?shopId=${shopId}&userId=${chatId}&queryId=${queryId}&commentary=${commentary}`,
    resData,
  );
};

const BasketService = {
  getBasketById,
  clearBasketById,
  addProductToBasket,
  sendBasket,
  sendBasketToChat,
};

export default BasketService;
