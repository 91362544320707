import { createAsyncThunk } from '@reduxjs/toolkit';
import ShopService from 'src/services/ShopService';

export const fetchShopFilters = createAsyncThunk(
  'filter/fetchAll',
  async (shopId: string, thunkAPI) => {
    try {
      const response = await ShopService.getShopFiltersById(shopId);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить фильтры магазина');
    }
  },
);
